import React, { Children, useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import logo from '../assests/logo.png'




const Navbar = () => {
    const [nav, setNav] = useState(false)
    const handleClick = () => setNav(!nav)

    return (
        <div className={`fixed z-50 w-full h-[80px] flex justify-between items-center px-4 ${window.location.pathname === "/study" ? "bg-transparent" : "bg-primary"}`}>
            <div className='pl-4'>
                <img src={logo} alt='logo image' style={{ width: '50px' }} />
            </div>



            <ul className='hidden md:flex text-white font-chakra'>
                <li className='px-4 cursor-pointer'>
                    <a href="/">Home</a>
                </li>
                <li className='px-4 cursor-pointer'>About</li>
                <li className='px-4 cursor-pointer'>Craft</li>
                <li className='px-4 cursor-pointer'>Projects</li>
                <li className='px-4 cursor-pointer'>
                    <a href='/study'>Study Center</a>
                </li>
            </ul>



            <div onClick={handleClick} className='md:hidden text-white z-10'>
                {!nav ? <FaBars /> : <FaTimes />}
            </div>

            <ul className={`absolute top-0 left-0 w-full h-screen bg-primary flex flex-col justify-center items-center text-white ${!nav ? 'hidden' : ''}`}>
                <li className='py-6 text-4xl'>Home</li>
                <li className='py-6 text-4xl'>About</li>
                <li className='py-6 text-4xl'>Craft</li>
                <li className='py-6 text-4xl'>Projects</li>
            </ul>
        </div>
    )
}


export default Navbar