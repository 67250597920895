import Navbar from "./components/Navbar";
import Home from "./components/Home";
import About from "./components/About";
import Craft from "./components/Craft";
import Footer from "./components/Footer";
import StudyCenter from "./components/StudyCenter";
import Projects from "./components/Projects";

// function App() {

//   return (
//     <div>
//       <Navbar />
//       <Home />
//       <About />
//       <Craft />
//       <StudyCenter />
//       <Footer />
//     </div>
//   );
// }

// export default App;

import { Routes, Route } from "react-router-dom";

export default function App() {
  return (
    <Routes>
      <Route index element={
        <>
          <Navbar />
          <Home />
          <About />
          <Craft />
          <Projects />
          <Footer />
        </>
      } />

      <Route path="/study" element={
        <>
          <Navbar />
          <StudyCenter />
        </>
      } />
    </Routes>
  );
}
