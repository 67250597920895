import React, { useEffect, useRef } from 'react';
import { FaArrowCircleDown } from 'react-icons/fa';


const Home = () => {
    const h2Ref = useRef(null);

    useEffect(() => {
        const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ</>';

        let interval;
        let iteration = 0;

        const animateText = () => {
            h2Ref.current.innerText = h2Ref.current.dataset.value
                .split('')
                .map((letter, index) => {
                    if (index < iteration) {
                        return letter;
                    }

                    return letters[Math.floor(Math.random() * 26)];
                })
                .join('');

            if (iteration >= h2Ref.current.dataset.value.length) {
                clearInterval(interval);
            }

            iteration += 1 / 3;
        };

        interval = setInterval(animateText, 35);

        return () => {
            clearInterval(interval);
        };
    }, []);



    return (
        <div name='home' className='w-full h-screen bg-primary'>
            <div className='max-w-[1000px] mx-auto flex flex-col justify-center h-full '>
                <p className='text-gray-300 font-bold text-4xl sm:text-6xl text-center font-chakra pb-3'>
                    Hi, welcome to
                </p>
                <h1 className='text-gray-300 font-bold text-4xl sm:text-6xl text-center font-chakra pb-5'>
                    my personal page !
                </h1>
                <div className='border border-secondary mx-auto w-[45%]'></div>
                <h2
                    ref={h2Ref}
                    className='font-bold text-transparent text-center text-4xl bg-clip-text bg-gradient-to-r p-4 from-accent to-secondary sm:text-5xl font-chakra'
                    data-value='< Full Stack Developer />'
                >
                    Full Stack Developer
                </h2>

                <div className='flex justify-center'>
                    <button className='text-accent animate-bounce'><FaArrowCircleDown /></button>
                </div>
            </div>
        </div>
    );
};

export default Home;
