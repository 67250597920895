import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SpotifyWebApi from "spotify-web-api-js";
import pause from '../assests/pause.png'
import play from '../assests/play.png'
// import video from '../assests/video.mp4';

const spotifyApi = new SpotifyWebApi();

const getTokenFromUrl = () => {
  return window.location.hash
    .substring(1)
    .split("&")
    .reduce((initial, item) => {
      let parts = item.split("=");
      initial[parts[0]] = decodeURIComponent(parts[1]);
      return initial;
    }, {});
};

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const StudyCenter = () => {
  const [spotifyToken, setSpotifyToken] = useState("");
  const [nowPlaying, setNowPlaying] = useState({});
  const [loggedIn, setLoggedIn] = useState(false);
  const [playbackMS, setPlaybackMS] = useState(0);

  const query = useQuery();

  useEffect(() => {
    console.log("This is what we get", getTokenFromUrl());
    const spotifyToken = query.get("access_token");
    window.location.hash = "";
    console.log("This is our spotify token", spotifyToken);

    if (spotifyToken) {
      setSpotifyToken(spotifyToken);
      spotifyApi.setAccessToken(spotifyToken);
      spotifyApi.getMe().then((user) => {
        console.log("USER:", user);
      });

      setLoggedIn(true);

      let interval = setInterval(() => {
        spotifyApi.getMyCurrentPlaybackState({}, (err, response) => {
          if (err) {
            console.error(err);
            return;
          }

          if (response.is_playing) {
            console.log("prgms", response.progress_ms.toLocaleString());
            console.log(response.item.duration_ms.toLocaleString());
          }

          setPlaybackMS(response.progress_ms);

          // console.log("NP", nowPlaying)
          // console.log("Prog", (Number.parseInt(playbackMS) / nowPlaying.duration) * 100)
          console.log("playbackms", playbackMS);
          console.log("mpdur", nowPlaying.duration);
        });
      }, 1000);

      getNowPlaying(); // Fetch the currently playing song and duration

      return function () {
        clearInterval(interval);
      };
    }
  }, []);

  const getNowPlaying = () => {
    spotifyApi.getMyCurrentPlaybackState().then((response) => {
      console.log(response);
      setNowPlaying({
        name: response.item.name,
        albumArt: response.item.album.images[0].url,
        artist: response.item.artists[0].name,
        duration: response.item.duration_ms,
      });
    });
  };

  const pausePlayback = async () => {
    await spotifyApi.pause();
  };

  const resumePlayback = async () => {
    await spotifyApi.play();
    getNowPlaying();
  };

  return (
    <>
      {/* <video src={video} autoPlay loop muted className="absolute top-0 left-0 w-full h-full object-cover" /> */}

      <div className="w-full h-screen  text-gray-300 font-chakra">
        <div className="flex justify-center items-center w-full h-full pt-10">
          {!loggedIn &&
            <div class='card3 bg-gray-800 px-5 hover:-translate-y-4 duration-500'>
              <h2 className="font-chakra font-bold text-center text-lg text-gray-300 z-10">
                Welcome!, this page is a study a work corner for you guys to have in the background as you work,
                you can sign in to your spotify and control it from this page !
                <p className="pt-10">
                  <button className="button1">
                    <a href="http://localhost:8888/">Login to Spotify</a>
                  </button>
                </p>
              </h2>
            </div>
          }

          {loggedIn && (
            <div className="container p-4 w-[350px] shadow-lg flex justify-center items-center sm:fixed sm:bottom-0 sm:right-0">
              <div className="music-player w-full rounded-lg bg-gray-800 p-4 shadow-lg">
                <div className="music-player__cover mb-4 flex justify-start items-center">
                  <img src={nowPlaying.albumArt} className="rounded-lg shadow-lg w-28 h-28 mr-4" alt="Album Art" />
                  <div className="music-player__details">
                    <p id="artistName" className="text-lg font-semibold">{nowPlaying.artist}</p>
                    <h2 id="songTitle" className="text-gray-500">{nowPlaying.name}</h2>
                  </div>
                </div>
                <div className="music-player__controls mb-4 pl-3 flex justify-center">
                  <button className="bg-accent text-gray-700 rounded-full p-2" onClick={pausePlayback}>
                    <img src={pause} alt="Pause" className="w-4 h-4" />
                  </button>
                  <button className="bg-accent text-gray-700 rounded-full p-2 mx-4" onClick={resumePlayback}>
                    <img src={play} alt="Play" className="w-4 h-4" />
                  </button>
                  <button className="bg-accent text-gray-700 rounded-full p-2 mx-4" onClick={getNowPlaying}>
                  </button>
                </div>
                <div className="music-player__progress">
                  <div className="bg-white h-2 rounded-full">
                    <div className="bg-accent h-full rounded-full" style={{ width: `${playbackMS / nowPlaying.duration * 100}%` }}>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StudyCenter;
