import React from 'react';
import { FaLinkedin, FaGithub, FaInstagram } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer className="fixed bottom-0 left-0 w-full bg-primary text-white p-4 flex justify-center items-center">
            <a href="https://linkedin.com/in/ayham-tamim" target="_blank" rel="noopener noreferrer" className="mr-4 transition-transform duration-300 transform hover:scale-125">
                <FaLinkedin size={24} />
            </a>
            <a href="https://github.com/Ayham1590" target="_blank" rel="noopener noreferrer" className="mr-4 transition-transform duration-300 transform hover:scale-125">
                <FaGithub size={24} />
            </a>
            <a href="https://www.instagram.com/ayhamtamimm_/" target="_blank" rel="noopener noreferrer" className="transition-transform duration-300 transform hover:scale-125">
                <FaInstagram size={24} />
            </a>
        </footer>
    );
};

export default Footer;
