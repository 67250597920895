import React, { useState } from 'react';

const cards = [
    {
        id: 1,
        description: "Developed an e-commerce website specifically designed to showcase a variety of inverters. The site was built using HTML to create a robust and visually appealing front-end interface. And then, the website was transitioned into PHP to introduce dynamic content management and server-side scripting capabilities. All data, including product details and user information, is efficiently stored in a MySQL database.",
        title: "ASJ Invertors",
        link: "https://github.com/Ayham1590/ASJ-Inverters.git"
    },
    {
        id: 2,
        description: "Developed a dynamic and responsive, visually appealing café website utilizing React and JavaScript. Incorporated a variety of creative CSS techniques to enhance the user experience and provide an aesthetically pleasing interface.",
        title: "Martin Cafe",
        link: "https://github.com/Ayham1590/Martin-Cafe.git"
    },
    {
        id: 3,
        description: "Developed a Kotlin mobile application designed to showcase and display LEGO collections, with the user interface meticulously crafted using Figma. ",
        title: "Logo Store",
        link: "https://github.com/Ayham1590/Logo-Store.git"
    },
    {
        id: 4,
        description: "Developed a creative personal portfolio using the framework Svelte.",
        title: "Svelte Portfolio",
        link: "https://github.com/Ayham1590/Svelte-Portfolio.git"
    },
    {
        id: 5,
        description: "Developed a small quiz made using Vue Js",
        title: "Vue Quiz",
        link: "https://github.com/Ayham1590/Vue-Quiz.git"
    },
];

const Projects = () => {
    const [activeCard, setActiveCard] = useState(null);
    const [currentCard, setCurrentCard] = useState(2);

    const handleButtonClick = (cardIndex) => {
        setActiveCard(cardIndex === activeCard ? null : cardIndex);
    };

    const handleCardClick = (card) => {
        if (card.link) {
            window.open(card.link, "_blank");
        }
    };

    const perc = currentCard * 5 - 10;

    return (
        <div className="w-full h-screen bg-primary text-gray-300 font-chakra pt-6">
            <div className="flex flex-col justify-center items-center w-full h-full">
                <div className="max-w-[1000px] w-full grid grid-cols-1 gap-8">
                    <div className="pl-4 pb-5 text-left sm:text-center">
                        <p className="text-4xl font-bold inline border-b-4 border-secondary font-chakra">
                            Projects
                        </p>
                    </div>
                </div>
                <div className="max-w-[600px] w-full grid sm:grid-cols-1 gap-8 ">
                    <div className="text-1xl font-bold sm:text-center text-left mb-5 pl-4">
                        <p>Here are some of my personal projects</p>
                    </div>
                </div>

                {/* Project cards */}
                <div className="relative flex flex-row w-full h-64 max-w-3xl mx-auto justify-left pl-4 items-left sm:justify-center sm:items-center bg-primary mb-2">
                    {
                        cards.map(card => {
                            return (
                                <div
                                    key={"projectcard" + card.id}
                                    className={`absolute flex flex-col items-center transition-opacity duration-[1300ms] cursor-pointer ${activeCard === card.id ? "opacity-100" : "opacity-0 pointer-events-none"}`}
                                    onClick={() => handleCardClick(card)}
                                >
                                    <div className="card">
                                        <div className="card2">
                                            <div className="card-description">
                                                <p className='text-sm px-2'>{card.description}</p>
                                            </div>
                                            <h1 className="mx-4 pt-4 text-xl">{card.title}</h1>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>

                {/* Project Select Buttons */}
                <div className="flex flex-row w-full max-w-8xl sm:mx-auto justify-center items-center bg-primary mt-8 overflow-visible ">
                    <div
                        className={`mx-auto flex w-[310px] sm:max-w-4xl sm:w-full flex-row items-center justify-center gap-x-2 sm:gap-x-6 transition-transform duration-300`}
                        style={{
                            // Translate the div based on the position of the 3rd button (the middle one)
                            transform: `translateX(${-perc}%)`,
                        }}
                    >
                        {cards.map((card, index) => {
                            return (
                                <div
                                    key={card.id}
                                    onClick={() => {
                                        setCurrentCard(index);
                                        setActiveCard(index + 1);
                                    }}
                                    className={`flex w-16 h-16 sm:w-32 sm:h-20 cursor-pointer rounded-lg flex-col items-center text-center justify-center font-bold text-xs sm:text-base flex-wrap transition-all duration-500 z-50 ${index === currentCard
                                        ? "bg-accent -translate-y-4 text-white "
                                        : "bg-gray-200 text-black hover:-translate-y-4"
                                        }`}
                                >
                                    {card.title}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Projects;
