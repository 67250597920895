import React from 'react'

const About = () => {
    return (
        <div name='about' className='w-full h-screen bg-primary text-gray-300 font-chakra'>
            <div className='flex flex-col justify-center items-center w-full h-full'>
                <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
                    <div className='sm:text-right pb-8 pl-4'>
                        <p className='text-4xl font-bold inline border-b-4 border-secondary font-chakra'>
                            About
                        </p>
                    </div>
                    <div>
                    </div>
                </div>
                <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
                    <div className='sm:text-right text-4xl font-bold'>
                        <p className=''>
                            Hey, my name is <span className='whitespace-nowrap text-secondary'><p className='inline-block transition-all duration-75 transform cursor-default text-secondary hover:-translate-y-2 '>A</p><p className='inline-block transition-all duration-75 transform cursor-default  hover:-translate-y-2'>y</p><p className='inline-block transition-all duration-75 transform cursor-default  hover:-translate-y-2 '>h</p><p className='inline-block transition-all duration-75 transform cursor-default  hover:-translate-y-2 '>a</p><p className='inline-block transition-all duration-75 transform cursor-default  hover:-translate-y-2'>m</p></span>
                            <span>.</span> Please take a look around!
                        </p>
                    </div>
                    <div>
                        <p>
                            A graduate in Software Engineering, I bring a fervent passion for technology, coupled
                            with a diverse skill set encompassing various frameworks and programming languages. With
                            hands-on experience in the field. Eager to apply knowledge in real-world settings to contribute
                            to innovative projects.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About